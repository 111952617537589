import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Link from '../../../core/Link';

import SolutionsPage from '../components/SolutionsPage';

import heritageLogo from '../../../../images/logos/Heritage-house-transparent-crop.svg';
import threeFallsLogo from '../../../../images/logos/three-falls-cove.png';
import eightDyer from '../../../../images/logos/8dyer-transparent.svg';
import trailsideLogo from '../../../../images/logos/Trailside-transparent-crop.svg';

import hero from './images/hero.png';
import support from './images/support_panel.png';
import payment from './images/payment_panel.png';
import simplicity from './images/simplicity_panel.png';
import booking_engine_home from './images/booking_engine_home.png';
import guest_experience from './images/guest_experience_panel.png';
import channel_manager_diagram from './images/channel_mgr_diagram.svg';

import CenteredCardWithImage from '../../../ui-library/Hero/CenteredCardWithImage';
import LogoScroll from '../../../ui-library/LogoScroll';
import SimpleFeatureGrid from '../../../ui-library/FeatureSections';
import TwoColumnCta from '../../../ui-library/TwoColumnCta';
import SimpleCTASection from '../../../ui-library/CTASections';
import Testimonial from '../../../ui-library/Testimonials/Testimonial';
import HeaderSection from '../../../ui-library/HeaderSection';
import SimpleCenteredTestimonial from '../../../ui-library/Testimonials/SimpleCenteredTestimonial';
import SimpleThreeColumn from '../../../ui-library/FeatureSections/SimpleThreeColumn';

const logos = [
  {
    src: heritageLogo,
  },
  {
    src: threeFallsLogo,
  },
  {
    src: eightDyer,
  },
  {
    src: trailsideLogo,
  },
];

export class MotelsPage extends Component {
  render() {
    return (
      <SolutionsPage>
        <Helmet title="Motel Reservation Management Software – ThinkReservations">
          <meta
            name="description"
            content="The motel reservation system from ThinkReservations can save you time and maximize your profitability. Click here to learn more."
          />
          <script
            type="application/ld+json"
            children={JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Service',
              serviceType: 'Motel Reservation Management Software Solutions',
              category:
                'Reservation Management Software for Small and Boutique Hotels',
              provider: {
                '@type': 'Organization',
                name: 'ThinkReservations',
              },
              areaServed: {
                '@type': 'Country',
                name: 'USA',
              },
            })}
          />
        </Helmet>
        <CenteredCardWithImage
          Img={() => (
            <img
              className="h-full w-full object-cover"
              src={hero}
              alt="A customer arriving at the front desk."
            />
          )}
          heading="Reservation Management Software For Motels + Motor Lodges"
          subheading="Running a modern motel, you face unique challenges and opportunities. From managing reservations to handling guest requests, you have a lot on your plate! And you know there are tools and technology that can help, but keeping up with it all can be a daunting task. That's why we've developed a suite of solutions for businesses like yours."
        />
        <SimpleFeatureGrid />
        <TwoColumnCta
          headline="Intuitive + Efficient Tools for Your Staff"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  Streamline your motel's operations without sacrificing quality
                  or service. Automate daily tasks that keep things running
                  smoothly while giving each employee the freedom to focus on
                  delivering the personalized experiences your guests expect.
                </p>
                <p>
                  From management, to front desk staff, to housekeeping, with
                  our powerful all-in-one{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    property management software
                  </Link>
                  , you’ll find your team saving time right away, able to
                  complete common tasks faster or knowing they’ll be done
                  through automation. On top of that, your business to-do list
                  will be organized and available with the click of a button.
                </p>{' '}
                If you’re new the world of PMS software, don’t worry. We'll help
                make sure your staff is ready to go from day one. No need to set
                aside days or even weeks to get your team up to speed.
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={simplicity}
              alt="Two staff members gathered at a laptop smiling."
            />
          )}
        />
        <div className="my-32"></div>
        <SimpleCenteredTestimonial
          quote={`We are so pleased to partner with Think - start to finish, there was never a doubt that we were in good hands. Every effort was made to make our onboarding process as seamless as possible and we are so appreciative for the support, guidance and all the attention to detail. The team is absolutely amazing, top notch customer service, and exceptional support.`}
          author="Donna + Asim Khawaja"
          position="Greenview Manor B&B"
        />
        <div className="my-32"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Where the Guest Experience Begins"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  If you’re used to taking a lot of phone reservations or seeing
                  a lot of day-of walk-ins, you’ll be thrilled to know that our
                  clients report a noticeable increase in online bookings. With
                  our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/booking-engine"
                  >
                    mobile-responsive booking engine.
                  </Link>
                  , guests can easily book direct right on your website, on any
                  wifi-connected device. Whether on a smartphone, laptop,
                  tablet, or desktop, they'll find the reservation process fast
                  and easy. And they’ll love that they’re booking a confirmed
                  reservation in real time. No worrying about availability or
                  waiting around for a confirmation.
                </p>{' '}
                <p>
                  Plus, you’ll be able to increase revenue year over year with
                  the ability to upsell guests on rooms, packages, and amenities
                  as they're making their reservations or anytime after.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={guest_experience}
              alt="A screenshot of the booking engine showing a room."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCTASection
          Img={() => (
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src={booking_engine_home}
              alt="App screenshot showing the landing page for the booking engine."
            />
          )}
        />
        <div className="my-24"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Stop Worrying About Double-Bookings"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  The days of stressing over double-bookings are GONE with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/channel-manager"
                  >
                    {' '}
                    channel manager
                  </Link>
                  . You can rest assured that your third-party OTA listings will
                  always be accurate and up-to date. We send real time two-way
                  availability and pricing to sites like Booking, Expedia,
                  Airbnb, HotelTonight, Tripadvisor, and more.
                </p>
                <p>
                  And if you’ve hesitated to use OTAs because of work involved
                  trying to manage everything manually, you’ll love being in
                  control of your OTA relationships. Only share what you want,
                  when it's best for your business. This allows you to maximize
                  your marketing reach while keeping your busiest rooms and
                  nights available for direct bookings only. Why pay commission
                  when you don’t have to?
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-slate-50 p-8">
              <img
                className="w-full"
                src={channel_manager_diagram}
                alt="A diagram outlining the several connections available in the channel manager."
              />
            </div>
          )}
        />{' '}
        <div className="my-44"></div>
        <TwoColumnCta
          headline="Make Data-Driven Business Decisions"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  It's no secret that operating a successful motel is
                  complicated. You have so many moving parts and it can be hard
                  to keep track of everything, but with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/articles/reports"
                  >
                    robust reporting
                  </Link>{' '}
                  features you'll always know what your doing right or where you
                  can improve! Get a high-level overview of how business is
                  going at any given time or deep dive with your team to keep
                  your business running smoothly.
                </p>
                <p>
                  You'll especially love our{' '}
                  <Link
                    className="text-think-blue"
                    to="/resources/webinars/reports"
                  >
                    Booking pace report
                  </Link>{' '}
                  . It tells you how many rooms are booked for any given period
                  and compare with another timeframe. Using your historical
                  booking data, you can see patterns and make predictions for
                  the future. This report is essential for forecasting
                  occupancy, making adjustments on the fly, and planning your
                  marketing strategy… and helping you make more revenue!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Marty E."
                position="Flag House Inn"
                quote="Think has supported a 40% increase in business. We did a back to back to back demo of 3 systems and Think was easy winner. We worked with them to plan a fast go live after we bought the business."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="Connect with Your Preferred Payment Processor"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  When it comes to taking payments, we know that you need to be
                  able to use the processor that's best for your business.
                  That's why we offer free integrations with a variety of{' '}
                  <Link
                    className="text-think-blue"
                    to="/products/payment-processing"
                  >
                    payment processors
                  </Link>{' '}
                  Take payments quickly, reliably, and securely with our{' '}
                  <Link
                    className="text-think-blue"
                    to="/articles/pci-compliance"
                  >
                    PCI-compliant software
                  </Link>
                  . Plus, you can safely store guest credit card details for
                  collecting balances at check-in or charging for extras during
                  their stay. And if you love convenience, you can even keep it
                  on file for future reservations!
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={payment}
              alt="A couple entering credit card info on a tablet."
            />
          )}
        />
        <div className="my-24"></div>
        <HeaderSection
          title="Your Favorite Third-Party Tools Integrated"
          copy={null}
          children={
            <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
              ThinkReservations offers{' '}
              <Link className="text-think-blue" to="/products/integrations">
                integrations
              </Link>{' '}
              with a variety of third-party software to make it even easier to
              run your business, with options like OTAs, point-of-sale, and
              guest communication. With these integrations, you can continue
              using the tools you love while benefitting from the efficiency and
              power of ThinkReservations. Manage all aspects of your business
              from one central platform, saving you time. Plus, our team is
              always working on new integrations to provide even more value to
              our customers.
            </p>
          }
        />
        <div className="my-24"></div>
        <LogoScroll logos={logos} />
        <div className="my-44"></div>
        <TwoColumnCta
          mediaLeft={true}
          headline="We’ll Import Your Data for a Smooth Transition"
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  We know one of the most common reasons businesses stay with
                  the wrong property management system is that they’re worried
                  about losing existing reservation and guest details.
                  Fortunately, when you upgrade to ThinkReservations, we'll
                  import your historic data for you - for free.
                </p>
                <p>
                  Feel comfortable knowing our team of experts has successfully
                  handled 1000+ data imports from a wide variety of property
                  management systems. We're confident we can do the same for
                  you. Focus on enjoying the benefits of ThinkReservations
                  without worrying about losing your valuable data.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <div className="bg-think-orange px-8">
              <Testimonial
                logo={null}
                avatar={null}
                name="Hermann"
                position="MO - Luxury Hotel"
                quote="ThinkReservations is a booking revolution This system will make it so easy to keep your business running with minimal effort. All the day to day operations of managing your reservations simply takes care of itself."
              />
            </div>
          )}
        />
        <div className="my-44"></div>
        <h1 className="max-w-7xl mx-auto px-4 mt-1 block text-4xl text-center tracking-tight font-extrabold sm:text-5xl">
          Our Customer Success Team Is Here to Help
        </h1>
        <SimpleThreeColumn />
        <TwoColumnCta
          children={
            <Fragment>
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                <p>
                  We take your success seriously. We know that happy customers
                  are the key to a successful business, so we go above and
                  beyond to make sure our clients are satisfied. And because
                  many of our team members have experience in the real-world
                  hospitality industry, you know we’ll treat you the way you
                  treat your guests.
                </p>
                <p>
                  You’ll receive comprehensive onboarding, account setup, and
                  training when you become a client. And into the future, you’ll
                  find our online self-service support center is packed with
                  articles and videos to answer any questions that come up. Our
                  US-based customer support team is available via email, phone,
                  and chat. Plus, we have emergency phone support after-hours in
                  the unlikely event you ever need it. So rest assured that when
                  you partner with us, you're partnering with a team that's
                  committed to your success.
                </p>
              </div>
            </Fragment>
          }
          Media={() => (
            <img
              className="w-full"
              src={support}
              alt="A happy customer talking with support."
            />
          )}
        />
        <div className="my-24"></div>
        <SimpleCenteredTestimonial
          quote={`The entire process, from speaking with a sales representative to installation and training to going live, was absolutely seamless on ThinkReservation's end. Would that all other digital transitions go as smoothly! Thank you very much!`}
          author="David Johnson"
          position="Tradewinds on the Bay"
        />
        <HeaderSection
          title="Supporting Independent Lodging Properties for Over 10 Years"
          copy={null}
          subtitle={null}
          children={
            <>
              <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
                <p>
                  The motel industry has come a long way in the last 10 years.
                  As travelers become increasingly sophisticated and discerning,
                  they're looking for more than just a place to stay. They want
                  an experience that is unique, personal and memorable. We know
                  what it takes to succeed and we're here to help you meet the
                  evolving needs of today's travelers. Meet
                  <Link className="text-think-blue" to="/products/integrations">
                    our team.
                  </Link>{' '}
                </p>
                Don't settle for anything less than a property management
                solution that will help you grow your business while delivering
                the exceptional experience your guests have come to expect.
              </p>
              <Link to="/request-a-demo">
                <button
                  className="block max-w-xl mx-auto w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                  type="submit"
                >
                  Request a demo today
                </button>
              </Link>
            </>
          }
        />
        <div className="my-24"></div>
      </SolutionsPage>
    );
  }
}

export default MotelsPage;
